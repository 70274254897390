.footer {
  background-color: #1a1a1a; /* Darker background for contrast */
  color: #f0f0f0; /* Light text color for readability */
  padding: 40px 20px; /* Padding for spacing */
  font-family: 'Arial', sans-serif;
  position: relative; /* Position for potential child elements */
}

.footer-container {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  justify-content: space-between;
  max-width: 1200px; /* Max width for alignment */
  margin: 0 auto; /* Centering */
}

.footer-column {
  flex: 1 1 200px; /* Flexible layout */
  margin: 15px; /* Spacing between columns */
  min-width: 220px; /* Minimum width to maintain structure */
  box-sizing: border-box; /* Include padding and border in width/height */
}

.footer-column h3 {
  color: #00bfff; /* Bright color for headings */
  margin-bottom: 10px; /* Space below heading */
  border-bottom: 2px solid #00bfff; /* Underline effect */
  padding-bottom: 5px; /* Padding below heading */
  transition: color 0.3s, border-color 0.3s; /* Smooth transition */
}

.footer-column h3:hover {
  color: #ffcc00; /* Change color on hover */
  border-color: #ffcc00; /* Change underline color on hover */
}

.footer-column p,
.footer-column ul {
  line-height: 1.6; /* Improved line height for readability */
  color: #cccccc; /* Softer color for text */
}

.footer-column a {
  color: #00bfff; /* Link color */
  text-decoration: none; /* Remove underline */
  transition: color 0.3s; /* Smooth color transition */
}

.footer-column a:hover {
  color: #ffcc00; /* Change link color on hover */
  text-decoration: underline; /* Underline on hover */
}

.social-icons {
  display: flex;
  gap: 15px; /* Spacing between icons */
  margin: 10px 0; /* Space below icons */
  margin-left: auto;
}

.social-icons a {
  color: #f0f0f0; /* Icon color */
  font-size: 1.5rem; /* Icon size */
  transition: transform 0.3s; /* Scale effect */
}

@media (max-width: 768px) {
  .social-icons {
    justify-content: center; /* Center icons on mobile */
  }
}

.social-icons a:hover {
  transform: scale(1.2); /* Scale effect on hover */
}

.quick-links {
  list-style: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
}

.quick-links li {
  margin: 5px 0; /* Spacing between links */
}

.footer-bottom {
  text-align: center; /* Centered text */
  margin-top: 20px; /* Space above */
  font-size: 0.9rem; /* Smaller font size */
}

.footer-love {
  margin-top: 5px; /* Space above */
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column; /* Stack columns vertically */
    align-items: center; /* Center align */
  }

  .footer-column {
    margin: 10px 0; /* Reduce margin for stacked layout */
    text-align: center; /* Center text in columns */
  }
}
