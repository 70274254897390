/* Testimonials Styles */
.testimonials-section {
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.testimonials-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.testimonials-container {
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: stretch; /* Align items to stretch the same height */
}

.testimonial {
  min-width: 300px; /* Minimum width for each testimonial */
  margin: 0 15px; /* Space between testimonials */
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column; /* Stack content vertically */
  transition: transform 0.3s ease-in-out;
}

.testimonial-content {
  font-style: italic;
  color: #666;
  flex-grow: 1; /* Allow the quote to take up available space */
}

.author-image {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.author-image img {
  width: 80px; /* Fixed size for author images */
  height: 80px;
  border-radius: 50%;
  object-fit: cover; /* Ensure the image fits well */
  border: 2px solid #007bff; /* Border for images */
}

.author-info {
  text-align: center;
  margin-top: 10px;
}

.author-info h4 {
  font-size: 1.2rem;
  margin: 5px 0 2px 0;
  color: #333;
}

.author-info p {
  font-size: 0.9rem;
  color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
  .testimonials-section h2 {
    font-size: 2rem; /* Smaller heading for mobile */
  }

  .testimonial {
    min-width: 100%; /* Full width on mobile */
    margin: 10px 0; /* Reduce margin */
  }
}
