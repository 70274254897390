/* General Section Styling */
.courses-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.courses-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
}

.courses-intro {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 30px;
  line-height: 1.6; /* Improve readability */
  max-width: 800px;
  margin-left: auto;
  margin-right: auto; /* Center intro text */
}

/* Course Container and Flex Layout */
.courses-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Individual Course Card Styling */
.course-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 300px; /* Fixed width for cards */
  height: auto; /* Automatic height to fit content */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.course-card:hover {
  transform: translateY(-5px); /* Subtle lift effect */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
}

.course-image {
  width: 100%;
  height: 180px; /* Fixed height for images */
  object-fit: cover; /* Maintain aspect ratio */
  border-radius: 5px;
  margin-bottom: 15px;
}

.course-details {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow details to fill space */
  justify-content: space-between; /* Space out content within the card */
}

/* Center the button */
.course-toggle-details {
  background-color: #007bff; /* Blue background for the button */
  color: white; /* White text color */
  border: none; /* Remove default border */
  padding: 10px 20px; /* Padding for a balanced button size */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Change cursor to pointer on hover */
  font-weight: bold; /* Make the text bold */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth color and transform transitions */
  margin-top: 10px; /* Space between the button and other elements */
  align-self: center; /* Center the button horizontally */
}

.course-toggle-details:hover {
  background-color: #0056b3; /* Darker blue when hovered */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

.course-toggle-details:focus {
  outline: none; /* Remove default outline */
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5); /* Add a blue focus ring */
}

.course-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #333;
}

.course-description,
.course-duration,
.course-demand,
.course-detailed-description {
  font-size: 0.9rem;
  color: #555;
  margin: 5px 0;
  line-height: 1.5; /* Improve line height for readability */
}

.course-title,
.course-description {
  font-weight: bold; /* Make the course title and description stand out */
}

.course-duration,
.course-demand {
  color: #777; /* Subtle color for less prominent details */
}

.course-delivery {
  font-weight: 600; /* Make delivery method stand out */
  color: #ff6347; /* Use a distinct color for delivery method */
}

.course-detailed-description {
  margin-top: auto; /* Push detailed description to the bottom */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .courses-container {
    flex-direction: column; /* Stack cards vertically on smaller screens */
    align-items: center;
  }

  .course-card {
    width: 90%; /* Make cards slightly larger on smaller screens */
    max-width: 500px;
  }

  .courses-title {
    font-size: 2rem; /* Smaller title on small screens */
  }

  .courses-intro {
    font-size: 1rem; /* Adjust intro text size */
  }
}

@media (max-width: 480px) {
  .course-card {
    width: 100%; /* Full width on very small screens */
    padding: 15px; /* Slightly smaller padding */
  }

  .courses-title {
    font-size: 1.8rem; /* Further adjust title size */
  }

  .courses-intro {
    font-size: 0.9rem; /* Smaller intro text */
  }
}
