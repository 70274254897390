.features-container {
  padding: 60px 20px;
  background: linear-gradient(135deg, #e2e2e2 0%, #ffffff 100%);
}

.features-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.feature-card {
  background: #ffffff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  width: 300px; /* Fixed width */
  height: auto; /* Adjust height to fit content */
  display: flex;
  flex-direction: column; /* Column layout for stacking */
  overflow: hidden;
  text-align: center;
  transform: scale(0.95);
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.feature-card-image img {
  width: 100%;
  height: 150px; /* Fixed height for images */
  object-fit: cover; /* Ensures images cover the area without distortion */
  border-bottom: 4px solid #007bff;
}

.feature-card-content {
  padding: 20px 15px; /* Adjusted padding */
  display: flex;
  flex-direction: column; /* Column layout for content */
  justify-content: space-between; /* Space between elements */
  height: 100%; /* Fill available height */
}

.feature-card-content h3 {
  font-size: 1.5rem; /* Slightly smaller font size */
  color: #333;
  margin: 10px 0; /* Adjusted margin */
  font-weight: bold;
}

.feature-card-content p {
  font-size: 1rem; /* Consistent font size */
  color: #666;
  margin-bottom: 15px; /* Slight bottom margin for spacing */
}

.feature-card-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin: 0; /* Reset margin */
  text-align: left; /* Left aligns list items */
}

.feature-card-content li {
  font-size: 0.9rem; /* Smaller font for list items */
  color: #333;
  margin-bottom: 5px; /* Adjusted margin */
}

header h2 {
  font-size: 2.5rem;
  color: #030f1b;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .feature-card {
    width: 45%; /* Two cards per row for tablet-sized screens */
  }

  header h2 {
    font-size: 2.25rem; /* Slightly smaller heading on mid-sized screens */
  }
}

@media (max-width: 768px) {
  .features-wrapper {
    gap: 20px; /* Reduced gap on smaller screens */
  }

  .feature-card {
    width: 100%; /* Full width on smaller screens */
    max-width: 350px; /* Optional max-width for larger screens */
  }

  header h2 {
    font-size: 2rem; /* Smaller font size */
  }

  .feature-card-content h3 {
    font-size: 1.25rem; /* Smaller headings on mobile */
  }

  .feature-card-content p {
    font-size: 0.9rem; /* Smaller paragraph text */
  }

  .feature-card-content ul {
    padding-left: 15px; /* Adjust padding for mobile */
  }
}

@media (max-width: 480px) {
  .feature-card {
    width: 100%; /* Ensure full width for very small screens */
    padding: 10px; /* Adjust padding for very small screens */
  }

  header h2 {
    font-size: 1.75rem; /* Even smaller font for very small screens */
  }

  .feature-card-content h3 {
    font-size: 1.125rem; /* Smaller headings */
  }

  .feature-card-content p {
    font-size: 0.85rem; /* Adjust text size */
  }
}
