.choose-us-container {
  padding: 40px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 1200px; /* Limit max width for large screens */
  margin: 0 auto; /* Center container */
}

.choose-us-title {
  text-align: center;
  font-size: 2.5rem; /* Increased font size */
  margin-bottom: 10px;
  color: #2c3e50; /* Darker color for better contrast */
}

.choose-us-introduction {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #333;
  line-height: 1.5; /* Better readability */
}

.choose-us-content {
  display: flex;
  flex-wrap: wrap; /* Enable wrapping for responsiveness */
  justify-content: center; /* Center items */
  gap: 20px; /* Space between items */
}

.choose-us-content-item {
  background: white;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  width: 90%; /* Default width for mobile */
  max-width: 300px; /* Max width for larger screens */
  transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for shadow */
  position: relative; /* For positioning effects */
}

.choose-us-content-item:hover {
  transform: translateY(-5px); /* Lift effect on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.choose-us-logo {
  width: 60px; /* Uniform logo size */
  height: auto;
  margin-bottom: 10px;
}

.choose-us-content-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #2980b9; /* Consistent theme color */
}

.choose-us-content-description {
  font-size: 0.9rem;
  color: #666;
  line-height: 1.4; /* Better readability */
}

/* Responsive Styles */
@media (min-width: 600px) {
  .choose-us-content-item {
    width: calc(33.333% - 20px); /* Three items per row on larger screens */
  }
}

@media (min-width: 900px) {
  .choose-us-content-item {
    width: calc(25% - 20px); /* Four items per row on very large screens */
  }
}
