body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.App {
  text-align: center;
}

.header {
  background-color: #000;
  padding: 20px;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
}

.nav a {
  color: white;
  margin-right: 30px;
  text-decoration: none;
}



.nav a:hover {
  text-decoration: underline;
}

.features-section {
  padding: 100px 20px;
  display: flex;
  justify-content: space-around;
}

.feature {
  width: 30%;
  text-align: center;
}

.feature img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.testimonials-section {
  padding: 100px 20px;
  background-color: #f8f9fa;
}

.footer {
  background-color: #000;
  color: white;
  padding: 20px;
  text-align: center;
}

.courses-section {
  padding: 2rem;
}

.courses-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.course-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-item img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.course-item h3 {
  margin-top: 1rem;
}

.course-item p {
  margin: 0.5rem 0;
}



.testimonials-section {
  padding: 2rem;
  background-color: #f4f4f4;
}

.testimonials-container {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 1rem;
}

.testimonial {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
  min-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.testimonial-content {
  margin-bottom: 1rem;
}

.testimonial-author {
  display: flex;
  align-items: center;
}

.author-image img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}

.author-info h4 {
  margin: 0;
}

.author-info p {
  margin: 0;
  color: #666;
}



.hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f4f4f4; 
  text-align: center;
  padding: 2rem;
  position: relative;
}

.hero-image {
  width: 100%;
  max-height: 50vh;
  overflow: hidden;
  position: relative;
}

.hero-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  display: block;
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  padding: 2rem;
  background: rgba(0, 0, 0, 0.5); 
  border-radius: 8px;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.cta-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;

}
