/* Hero Section Styles */
.hero-section {
  display: flex; /* Flexbox for layout */
  flex-direction: column; /* Stack elements vertically by default */
  align-items: center; /* Center items horizontally */
  padding: 50px 20px; /* Add padding around the content */
  background-color: #ffffff; /* Simple solid white background */
  position: relative; /* For positioning elements */
  color: #333; /* Dark text color for contrast */
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2); /* Subtle text shadow for better visibility */
  border-radius: 10px; /* Rounded corners for modern look */
  max-width: 100%; /* Ensure max width is responsive */
  overflow: hidden; /* Prevent content from spilling out */
  box-sizing: border-box;
}

.hero-image {
  width: 100%; /* Full width */
  max-height: 400px; /* Limit height */
  overflow: hidden; /* Hide overflow */
  border-radius: 8px; /* Rounded corners */
  margin-bottom: 30px; /* Space below the image */
  position: relative;
}

.hero-image img {
  width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  object-fit: cover; /* Cover the area */
  filter: brightness(60%); /* Darken the image slightly for text contrast */
}

/* Gradient Overlay on Image for Better Contrast */
.hero-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3); /* Black overlay with some transparency */
  border-radius: 8px;
}

.hero-content {
  text-align: center; /* Center text alignment */
  max-width: 700px; /* Limit max width */
  margin: 0 auto; /* Center horizontally */
  padding: 20px; /* Add padding for better spacing */
  box-sizing: border-box;
  overflow: hidden; /* Prevent overflow */
}

h1 {
  font-size: 3rem; /* Larger font size for better visibility */
  color: #2c3e50; /* Dark text for contrast */
  margin-bottom: 20px; /* Space below heading */
  font-weight: 700; /* Bold text for prominence */
  line-height: 1.3; /* Add line-height for better readability */
  word-wrap: break-word; /* Ensure words break on long text */
}

p {
  font-size: 1.2rem; /* Comfortable font size */
  color: #555; /* Dark gray color for readability */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 30px; /* Space below paragraphs */
  max-width: 90%; /* Avoid text stretching too wide */
  margin-left: auto; /* Center text */
  margin-right: auto;
  word-wrap: break-word; /* Avoid text overflow */
}

.cta-button {
  padding: 15px 25px; /* Comfortable padding for the button */
  font-size: 1.3rem; /* Larger text for readability */
  color: white; /* White text */
  background-color: #3498db; /* Primary button color */
  border: none; /* No border */
  border-radius: 5px; /* Rounded button corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Smooth transitions */
  text-transform: uppercase; /* Uppercase for a more modern look */
  letter-spacing: 1px; /* Add spacing to text for a cleaner look */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); /* Slight shadow for depth */
  margin-bottom: 30px; /* Space below the button */
}

.cta-button:hover {
  background-color: #2980b9; /* Darker color on hover */
  transform: translateY(-4px); /* Lift effect on hover */
}

.intro-text {
  font-size: 1.1rem; /* Slightly smaller font for intro text */
  color: #666; /* Lighter gray for less emphasis */
  margin-top: 20px; /* Space above intro text */
  font-style: italic; /* Italicize for a unique look */
  line-height: 1.5;
  max-width: 90%; /* Prevent text from stretching too wide */
  margin-left: auto; /* Center text */
  margin-right: auto;
}

/* Responsive Styles */
@media (min-width: 600px) {
  .hero-section {
    flex-direction: row; /* Horizontal layout on larger screens */
    padding: 50px; /* More space on larger screens */
  }

  .hero-image {
    flex: 1; /* Allow image to take more space */
    margin-right: 20px; /* Add space between image and content */
  }

  .hero-content {
    flex: 1; /* Allow content to take more space */
  }

  h1 {
    font-size: 3.5rem; /* Larger font size on wider screens */
  }

  p {
    font-size: 1.3rem; /* Slightly larger font size on wider screens */
  }

  .cta-button {
    font-size: 1.5rem; /* Larger button text on wider screens */
    padding: 15px 30px; /* Larger button size */
  }

  .intro-text {
    font-size: 1.2rem; /* Slightly larger intro text */
  }
}

@media (max-width: 768px) {
  .hero-section {
    padding: 30px 15px; /* Less padding on small screens */
  }

  h1 {
    font-size: 2.2rem; /* Smaller heading on mobile */
  }

  p {
    font-size: 1.1rem; /* Smaller paragraph on mobile */
  }

  .cta-button {
    font-size: 1.2rem; /* Smaller button text on mobile */
    padding: 12px 20px; /* Smaller button padding */
  }

  .intro-text {
    font-size: 1rem; /* Smaller intro text on mobile */
  }
}
