.faq-container {
  padding: 60px 20px;
  background-color: #ffffff; /* Clean background for readability */
  max-width: 1200px; /* Center content and limit max width */
  margin: 0 auto; /* Center container horizontally */
}

.search-bar {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.search-bar input {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for input */
}

h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.5rem; /* Larger font size for better readability */
  color: #333;
  font-weight: 600; /* Slightly heavier font weight */
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 600; /* Bold font weight */
  color: #333;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.accordion-button:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Slightly larger shadow on hover */
}

.accordion-icon {
  width: 24px; /* Slightly larger icon for better visibility */
  height: 24px;
  border: 1px solid #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, background-color 0.3s; /* Added transition for background color */
}

.accordion-icon.expanded {
  transform: rotate(45deg);
  background-color: #007bff; /* Change color on expansion */
  border-color: #007bff;
}

.accordion-panel {
  padding: 20px;
  background-color: #f9f9f9; /* Lighter background for readability */
  border-top: 1px solid #ddd;
  font-size: 1rem;
  color: #555;
  border-radius: 0 0 8px 8px; /* Rounded corners at the bottom */
}

.accordion-panel p {
  margin: 0;
}

mark {
  background-color: #ffeb3b; /* Subtle yellow for highlighting */
  color: #333; /* Dark text for readability */
}

@media (max-width: 768px) {
  .search-bar input {
    font-size: 0.9rem;
  }

  h2 {
    font-size: 2rem; /* Slightly smaller font size for smaller screens */
  }

  .accordion-button {
    font-size: 1rem;
  }

  .accordion-panel p {
    font-size: 0.9rem;
  }
}
