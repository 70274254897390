/* Basic reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px; /* Comfortable padding */
  background-color: #1e1e1e; /* Dark background */
  color: white; /* White text */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  transition: background-color 0.3s ease;
}

.header.open {
  background-color: #333; /* Darker on menu open */
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-width: 100px; /* Consistent size */
}

.logo img {
  max-height: 30px; /* Keep it compact */
  width: auto;
  filter: brightness(0) invert(1); /* Invert colors for better visibility */
}

.menu-icon {
  display: none; /* Hidden on larger screens */
  flex-direction: column;
  cursor: pointer;
  z-index: 1001; /* Above other elements */
}

.menu-icon .bar {
  background-color: white; /* White bars for contrast */
  height: 3px;
  width: 25px; /* Slightly smaller */
  margin: 4px 0;
  transition: all 0.3s ease;
}

.menu-icon.open .bar:nth-child(1) {
  transform: rotate(45deg) translateY(7px);
}

.menu-icon.open .bar:nth-child(2) {
  opacity: 0; /* Hide the middle bar */
}

.menu-icon.open .bar:nth-child(3) {
  transform: rotate(-45deg) translateY(-7px);
}

.nav-menu {
  display: flex;
  gap: 20px; /* Space between links */
}

.nav-menu a {
  text-decoration: none;
  color: white; /* Link color */
  font-size: 14px; /* Adjusted font size */
  font-weight: 500; /* Medium weight for readability */
  padding: 5px 10px; /* Add padding for better touch targets */
  border-radius: 5px; /* Rounded corners */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav-menu a:hover {
  background-color: rgba(255, 99, 71, 0.2); /* Light tomato background on hover */
  color: #ff6347; /* Change text color on hover */
}

/* Mobile menu styles */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: stretch; /* Align children to fill the header width */
    padding: 5px; /* Minimal padding */
  }

  .logo {
    margin-bottom: 5px; /* Space between logo and menu icon */
    width: 100%;
    text-align: center; /* Center logo */
  }

  .menu-icon {
    display: flex; /* Show the menu icon on mobile */
    position: absolute;
    top: 10px;
    left: 10px; /* Position in the left corner */
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Space between links */
    width: 100%;
    background-color: #1e1e1e; /* Match header background */
    position: absolute;
    top: 80px; /* Adjust based on header height */
    left: 0;
    padding: 10px; /* Minimal padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(-20px);
    opacity: 0;
    z-index: 1001; /* Ensure it appears above other content */
  }

  .nav-menu.show {
    opacity: 1;
    transform: translateY(0); /* Slide in effect */
  }
}

/* Large screens */
@media (min-width: 769px) {
  .logo {
    max-width: 100px; /* Keep it consistent */
  }
}
