.course-selection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

.course-selection h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.course-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.course-button {
  padding: 12px 20px;
  font-size: 1.1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.course-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.course-button.selected {
  background-color: #0056b3;
  font-weight: bold;
}

.confirmation-message {
  margin-top: 20px;
  font-size: 1.1rem;
  color: #333;
}
